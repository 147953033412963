import { useEffect } from 'react';
import { useForm, useController } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button, Card, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Plus, Save, Trash } from 'react-feather';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  useProfileFormPageLazyQuery,
  CloudFileInput,
  useCreatePoseProfileMutation,
  useUpdatePoseProfileMutation,
  useDeletePoseProfileMutation,
} from 'codegen/graphql';
import Page from 'pages/Page';
import { id, gid } from 'lib/gid';
import { handleMutation } from 'lib/handleMutation';
import { toast } from 'src/components/GlobalSnackbar';
import TextField from 'components/TextField';
import FileField from 'components/FileField';

type ProfileFormPageProps = {
  /**
   * Model ID of the filter to edit. If not provided, a new filter is assumed.
   */
  id?: string;
};

const profileFormSchema = z
  .object({
    name: z.string().refine((name) => name?.trim().length, 'Must not be blank'),
    description: z.string().optional(),
  })
  .passthrough();

export type ProfileFormType = z.infer<typeof profileFormSchema> & {
  exampleImages: CloudFileInput[];
  outlineImage: CloudFileInput | null;
};

const useStyles = makeStyles((theme) => ({
  cardBody: {
    '& > *': {
      display: 'block',
    },
    '& > *:not(:last-child)': {
      marginBottom: 16,
    },
  },
  deleteButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export function ProfileFormPage(props: ProfileFormPageProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  /**
   * GID of the profile being edited. If this value is `null`, then a new
   * profile is being created.
   */
  const profileGid = props.id ? gid(props.id, 'PoseProfile') : null;

  const { control, trigger, getValues, reset } = useForm<ProfileFormType>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      name: '',
      description: '',
      exampleImages: [],
      outlineImage: null,
    },
  });

  const nameController = useController({ control, name: 'name' });
  const descriptionController = useController({ control, name: 'description' });
  const exampleImagesController = useController({ control, name: 'exampleImages' });
  const outlineImageController = useController({ control, name: 'outlineImage' });

  const [getExistingProfile, getExistingProfileState] = useProfileFormPageLazyQuery();

  // If profileGid is defined, fetch the existing profile and pre-fill the form
  // with data
  useEffect(() => {
    if (profileGid) {
      getExistingProfile({ variables: { id: profileGid } });
    }
    // eslint-disable-next-line
  }, []);

  // If we are receiving new existing profile data, then pre-fill the form with
  // data, or handle errors accordingly.
  useEffect(() => {
    if (!profileGid) return;
    const { data, error } = getExistingProfileState;
    const existingProfile = data?.poseProfile;

    if (existingProfile) {
      reset({ ...existingProfile, name: existingProfile?.name ?? '', description: existingProfile?.description ?? '' });
    } else if (error) {
      history.push('/admin/profiles');
      toast('Could not load existing profile', 'error');
    }
    // eslint-disable-next-line
  }, [getExistingProfileState]);

  const [createProfile, createProfileState] = useCreatePoseProfileMutation();
  const [updateProfile, updateProfileState] = useUpdatePoseProfileMutation();
  const [deleteProfile, deleteProfileState] = useDeletePoseProfileMutation();

  return (
    <Page
      title={profileGid ? 'Update pose profile' : 'New pose profile'}
      breadcrumbs={[
        {
          to: '/admin',
          body: 'Admin settings',
        },
        {
          to: '/admin/profiles',
          body: 'Pose profiles',
        },
        profileGid
          ? {
              to: `/admin/profiles/${id(profileGid)}/edit`,
              body: 'Edit',
            }
          : {
              to: '/admin/profiles/new',
              body: 'New',
            },
      ]}
      pageActions={
        <>
          {profileGid && (
            <Button
              className={classes.deleteButton}
              startIcon={<Trash />}
              variant="contained"
              disabled={getExistingProfileState.loading || deleteProfileState.loading}
              onClick={() => handleDelete(profileGid)}
            >
              {deleteProfileState.loading ? 'DELETING...' : 'DELETE'}
            </Button>
          )}
          <Button
            color="primary"
            startIcon={profileGid ? <Save /> : <Plus />}
            variant="contained"
            disabled={getExistingProfileState.loading || createProfileState.loading || updateProfileState.loading}
            onClick={profileGid ? () => handleUpdate(profileGid) : handleCreate}
          >
            {createProfileState.loading || updateProfileState.loading ? 'SAVING...' : 'SAVE'}
          </Button>
        </>
      }
    >
      {getExistingProfileState.loading ? (
        <ProfileFormCardSkeleton />
      ) : (
        <Card>
          <div className={classes.cardBody}>
            <TextField label="Name" controller={nameController} variant="outlined" fullWidth />
            <TextField label="Description" controller={descriptionController} variant="outlined" fullWidth multiline rows={4} />
            <FileField
              label="Example images"
              fileName="global_pose_profile_example"
              controller={exampleImagesController}
              multiple
            />
            <FileField
              label="Outline image"
              fileName="global_pose_profile_outline"
              controller={outlineImageController}
            />
          </div>
        </Card>
      )}
    </Page>
  );

  async function handleCreate() {
    if (!(await trigger())) return;
    handleMutation({
      mutate: () => createProfile({ variables: { input: getValues() } }),
      payloadFieldPath: 'createPoseProfile?',
      successCondition: (data) => !!data.createPoseProfile?.poseProfile?.id,
      onSuccess: () => {
        history.push('/admin/profiles');
        toast('Pose profile created', 'success');
      },
      onError: () => {
        toast('Could not create pose profile', 'error');
      },
    });
  }

  async function handleUpdate(id: string) {
    if (!(await trigger())) return;
    handleMutation({
      mutate: () => updateProfile({ variables: { input: { id, ...getValues() } } }),
      payloadFieldPath: 'updatePoseProfile?',
      successCondition: (data) => !!data.updatePoseProfile?.poseProfile?.id,
      onSuccess: () => {
        toast('Pose profile updated', 'success');
      },
      onError: () => {
        toast('Could not update pose profile', 'error');
      },
    });
  }

  async function handleDelete(id: string) {
    handleMutation({
      mutate: () => deleteProfile({ variables: { id } }),
      payloadFieldPath: 'deletePoseProfile?',
      confirm: 'Are you sure you want to delete this global pose profile?',
      successCondition: (data) => data?.deletePoseProfile?.deletedId === id,
      onSuccess: () => {
        history.push('/admin/profiles');
        toast('Pose profile deleted', 'success');
      },
      onError: () => {
        toast('Could not delete pose profile', 'error');
      },
    });
  }
}

function ProfileFormCardSkeleton(): JSX.Element {
  const classes = useStyles();
  return (
    <Card>
      <div className={classes.cardBody}>
        <Skeleton height={56} />
        <Skeleton height={139} />
        <Skeleton height={139} />
      </div>
    </Card>
  );
}
