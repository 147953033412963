import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route as BaseRoute,
  Redirect,
  RouteProps as BaseRouteProps,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ApolloProvider } from '@apollo/client/react';
import { ThemeProvider } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { z } from 'zod';

import customTheme from '../src/theme';
import initI18n from '../src/i18n';
import AppWrapper from 'layouts/AppWrapper';
import { StudiosPage } from 'pages/StudiosPage';
import Presets from 'pages/Presets';
import Submissions from 'pages/Submissions';
import SubmissionNotifications from 'pages/SubmissionNotifications';
import ContactsPage from 'pages/ContactsPage';
import Integrations from 'pages/Integrations';
import { DashboardPage } from 'pages/Dashboard';
import Filters from 'pages/Filters';
import FilterFormPage from 'pages/FilterFormPage';
import PresetFormPage from 'pages/PresetFormPage';
import { StudioSettingsPage } from 'src/pages/StudioSettingsPage';
import { PosesPage } from 'pages/PosesPage';
import Login from 'pages/Login';
import { StudioFormPage } from 'src/pages/StudioFormPage';
import { Contact } from 'pages/Contact';
import { AdminPage } from 'pages/AdminPage';
import { ProfilesPage } from 'pages/ProfilesPage';
import { ProfileFormPage } from 'pages/ProfileFormPage';
import { ContactFormPage } from 'pages/ContactFormPage';

import { initializeApolloClient } from 'lib/initializeApolloClient';
import { GlobalSnackbar } from 'components/GlobalSnackbar';
import ErrorCard from 'components/ErrorCard';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { NotAuthorizedPage } from 'pages/NotAuthorizedPage';
import { usePermissions } from 'hooks/usePermissions';
import { Export } from 'pages/Export/Export';
import { ContactAttributesPage } from 'pages/ContactFieldsPage/ContactAttributesPage';
import { StudioContactFieldsPage } from 'pages/StudioContactFieldsPage/StudioContactFieldsPage';
import { MetricsPage } from 'pages/MetricsPage/MetricsPage';
import { TokensPage } from 'src/pages/TokensPage/TokensPage';
import { ResourcesPage } from 'pages/ResourcesPage';
import { PageErrorBoundary } from 'components/ErrorBoundary/PageErrorBoundary';
import 'lib/datadog';
import { PresetGlobalScope } from 'codegen/graphql';

// obtain CSRF token from meta tag header
export const CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

// Start MSW if in development and USE_MSW is 'true'. The top-level await never
// reaches production code, as vite:esbuild-transpile will throw if it does.
if (import.meta.env.DEV && import.meta.env.VITE_USE_MSW === 'true') {
  import('public/mockServiceWorker');
  const { worker } = await import('mocks/browser');
  worker.start();
  worker.printHandlers();
}

// Initialize apollo client *after* MSW starts.
const apolloClient = initializeApolloClient(CSRF_TOKEN);

// Initialize i18n.
initI18n();

// Set up human-readable error messages for field validations. See
// https://github.com/colinhacks/zod/issues/97.
z.setErrorMap((err, ctx) => {
  if (err.code === 'invalid_type' && (err.received === 'null' || err.received === null || err.received === undefined)) {
    return { message: 'Must not be blank' };
  }

  return { message: ctx.defaultError };
});

// Initialize global styles.
const useStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
    },
    html: {
      background: theme.palette.grey[50],
      // hide blue outline when focused in any data grid cells
      '& .MuiDataGrid-root': {
        '& .MuiDataGrid-cell': {
          '&:focus,:focus-within': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-columnHeader': {
          '&:focus,:focus-within': {
            outline: 'none',
          },
        },
      },
    },
    body: {
      minHeight: '100vh',
      margin: 0,
    },
    'div#root': {
      minHeight: '100vh',
    },
    // do not show "black border" on mouse focus in chrome
    '*:focus:not(:focus-visible)': {
      outline: 'none',
    },
    // stop transforming height to 60% by default. why is it expected behavior
    // that when i specify `height: 100px` that i really mean `height: 60px`?
    '.MuiSkeleton-root': {
      transform: 'none',
    },
  },
}));

function Providers(props: { children: React.ReactNode }) {
  const classes = useStyles();
  return (
    <Router>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={customTheme}>
          {/* apply global styles */}
          <div className={classes['@global']} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>{props.children}</MuiPickersUtilsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Router>
  );
}

/**
 * Wrapper around the `react-router-dom` `Route` component that accepts a
 * boolean `authorized` prop. If `authorized` is false, it renders a "not
 * authorized" page instead. `authorized` is `true` by default, which means if
 * the route is open to all users unless otherwise specified.
 */
function Route<T extends Record<string, unknown> = Record<string, unknown>, Path extends string = string>(
  props: { authorized?: boolean } & BaseRouteProps<Path>,
): JSX.Element {
  const { authorized = true, children, ...baseRouteProps } = props;
  return <BaseRoute {...baseRouteProps}>{authorized ? children : <NotAuthorizedPage />}</BaseRoute>;
}

function AdminRoutes() {
  const perms = usePermissions();

  if (perms.loading) return null;

  if (perms.error) {
    return <ErrorCard body="Fatal error: unable to fetch membership permissions. Please contact support." />;
  }

  const { staff } = perms;

  return (
    <Switch>
      <Route authorized={staff} path="/admin" exact>
        <AdminPage />
      </Route>
      <Route authorized={staff} path="/admin/profiles" exact>
        <ProfilesPage />
      </Route>
      <Route authorized={staff} path="/admin/profiles/new" exact>
        <ProfileFormPage />
      </Route>
      <Route
        authorized={staff}
        path={['/admin/profiles/:id', '/admin/profiles/:id/edit']}
        exact
        render={({ match }) => <ProfileFormPage id={match.params.id} />}
      />
      <Route authorized={staff} path="/admin/presets" exact>
        <Presets globalScope={PresetGlobalScope.Portal} />
      </Route>
      <Route authorized={staff} path="/admin/presets/new" exact>
        <PresetFormPage globalScope={PresetGlobalScope.Portal} />
      </Route>
      <Route
        authorized={staff}
        path="/admin/presets/:id/edit"
        exact
        render={({ match }) => <PresetFormPage globalScope={PresetGlobalScope.Portal} id={match.params.id} />}
      />
      <Route authorized={staff} path="/admin/filters" exact>
        <Filters />
      </Route>
      <Route authorized={staff} path="/admin/filters/new" exact>
        <FilterFormPage />
      </Route>
      <Route
        authorized={staff}
        path={['/admin/filters/:id', '/admin/filters/:id/edit']}
        exact
        render={({ match }) => <FilterFormPage id={match.params.id} />}
      />
      <Route authorized={staff} path="/admin/metrics" exact>
        <MetricsPage />
      </Route>
      <Route authorized={staff} path="/admin/tokens" exact>
        <TokensPage />
      </Route>
    </Switch>
  );
}

function AccountRoutes() {
  const perms = usePermissions();

  if (perms.loading) return null;

  if (perms.error) {
    return <ErrorCard body="Fatal error: unable to fetch membership permissions. Please contact support." />;
  }

  const { auth } = perms;

  return (
    <AppWrapper view="account">
      <Switch>
        <Route path="/" exact>
          <DashboardPage />
        </Route>
        <Route path="/submissions/exports/:id">
          <Export modelName="SubmissionsExport" />
        </Route>
        <Route authorized={auth.canViewStudio} path="/studios" exact>
          <StudiosPage />
        </Route>
        <Route authorized={auth.canAddStudio} path="/studios/new" exact>
          <StudioFormPage />
        </Route>
        <Route
          authorized={perms.staff}
          path="/studios/:id/edit"
          render={({ match }) => <StudioFormPage id={match.params.id} />}
        />
        <Route
          authorized={auth.canChangeStudio}
          path="/studios/:id/settings"
          exact
          render={({ match }) => <StudioSettingsPage id={match.params.id} />}
        />
        <Route
          authorized={auth.canChangeStudio}
          path="/studios/:id/poses"
          exact
          render={({ match }) => <PosesPage studioId={match.params.id} />}
        />
        <Route
          authorized={auth.canChangeStudio}
          path="/studios/:id/fields"
          exact
          render={({ match }) => <StudioContactFieldsPage studioId={match.params.id} />}
        />

        <Route authorized={auth.canViewPreset} path="/presets" exact>
          <Presets />
        </Route>
        <Route authorized={auth.canAddPreset} path="/presets/new" exact>
          <PresetFormPage />
        </Route>
        <Route
          authorized={auth.canChangePreset}
          path="/presets/:id/edit"
          exact
          render={({ match }) => <PresetFormPage id={match.params.id} />}
        />
        <Route authorized={auth.canViewContact} path={['/contacts', '/contacts/lists']} exact>
          <Redirect to="/contacts/lists/all" />
        </Route>
        <Route
          authorized={auth.canViewContact}
          path="/contacts/lists/:id"
          exact
          render={({ match }) => <ContactsPage id={match.params.id} />}
        />
        <Route
          authorized={auth.canAddContact}
          path="/contacts/new"
          exact
          render={({ history }) => <ContactFormPage selectedListId={history.location.state?.['selectedListId']} />}
        />
        <Route authorized={perms.staff} path="/contacts/attributes" exact render={() => <ContactAttributesPage />} />
        <Route
          authorized={auth.canViewContact}
          path="/contacts/:id/view"
          exact
          render={({ match }) => <Contact id={match.params.id} />}
        />
        <Route
          authorized={auth.canChangeContact}
          path="/contacts/:id/edit"
          exact
          render={({ match }) => <ContactFormPage id={match.params.id} />}
        />
        <Route path="/contacts/exports/:id">
          <Export modelName="ContactsExport" />
        </Route>
        <Route authorized={auth.canViewSubmission} path="/submissions" exact>
          <Redirect to="/submissions/all" />
        </Route>
        <Route path="/resources" exact>
          <ResourcesPage />
        </Route>
        <Route authorized={auth.canViewSubmission} path="/submissions/all" exact>
          <Submissions selectedTab="ALL" />
        </Route>
        <Route authorized={auth.canViewSubmission} path="/submissions/review" exact>
          <Submissions selectedTab="REVIEW" />
        </Route>
        <Route authorized={auth.canViewSubmission} path="/submissions/accepted" exact>
          <Submissions selectedTab="ACCEPTED" />
        </Route>
        <Route authorized={auth.canViewSubmission} path="/submissions/rejected" exact>
          <Submissions selectedTab="REJECTED" />
        </Route>
        <Route authorized={auth.canViewSubmission} path="/submissions/invited" exact>
          <Submissions selectedTab="INVITED" />
        </Route>
        <Route authorized={auth.canViewSubmission && auth.canViewStudio} path="/user/settings/notifications" exact>
          <SubmissionNotifications />
        </Route>
      </Switch>
    </AppWrapper>
  );
}

/**
 * The root `App` component that serves as the entry point of the Holar Admin UI.
 */
export function App(): JSX.Element | null {
  const { ready } = useTranslation();

  if (!ready) return null;

  if (!CSRF_TOKEN && import.meta.env.VITE_USE_MSW !== 'true') {
    return <ErrorCard body="Fatal error: unable to parse CSRF token from meta tag. Please contact support." />;
  }

  return (
    <Providers>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/admin">
          <AppWrapper view="admin">
            <AdminRoutes />
          </AppWrapper>
        </Route>
        <Route path="/dashboard" exact>
          <AppWrapper view="user">
            <DashboardPage />
          </AppWrapper>
        </Route>
        <AccountRoutes />
      </Switch>
      <GlobalSnackbar />
    </Providers>
  );
}

ReactDOM.render(
  <ErrorBoundary fallback={(error) => <PageErrorBoundary />}>
    <StrictMode>
      <App />
    </StrictMode>
  </ErrorBoundary>,
  getRootElement(),
);

function getRootElement(): Element {
  let $root = document.getElementById('root');
  if ($root) return $root;

  $root = document.createElement('div');
  $root.id = 'root';

  document.body.appendChild($root);

  return $root;
}
