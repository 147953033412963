import { ApolloError } from '@apollo/client';
import { useGetPermissionsQuery } from 'codegen/graphql';

const permissionsMap = {
  canAddStudio: 'studio:add',
  canViewStudio: 'studio:view',
  canChangeStudio: 'studio:change',
  canDeleteStudio: 'studio:delete',
  canAddPreset: 'preset:add',
  canViewPreset: 'preset:view',
  canChangePreset: 'preset:change',
  canDeletePreset: 'preset:delete',
  canAddContact: 'contact:add',
  canViewContact: 'contact:view',
  canChangeContact: 'contact:change',
  canDeleteContact: 'contact:delete',
  canAddSubmission: 'submission:add',
  canViewSubmission: 'submission:view',
  canChangeSubmission: 'submission:change',
  canDeleteSubmission: 'submission:delete',
  canExportSubmission: 'submission:export',
  canInviteSubmission: 'submission:invite',
  canViewStudioSession: 'studiosession:view',
  canAddStudioSession: 'studiosession:add',
  canDeleteStudioSession: 'studiosession:delete',
};

type PermissionKey = keyof typeof permissionsMap;

export type AuthedPermissions = Record<PermissionKey, boolean>;

type UsePermissionsLoading = {
  loading: true;
  error: null;
  staff: null;
  auth: null;
};

type UsePermissionsFailure = {
  loading: false;
  error: Error | ApolloError;
  staff: null;
  auth: null;
};

type UsePermissionsSuccess = {
  loading: false;
  error: null;
  staff: boolean;
  auth: AuthedPermissions;
};

type UsePermissionsReturn = UsePermissionsLoading | UsePermissionsFailure | UsePermissionsSuccess;

/**
 * Hook that returns a map of keys and a boolean of whether the permission was
 * authorized. Note that this hook is asynchronous, and that you need to catch
 * the loading and error states.
 *
 * @example
 * const perms = usePermissions()
 *
 * if (permissionsState.loading) return null
 * if (permissionsState.error) return null
 *
 * const { canAddStudio, canChangeStudio } = perms.auth;
 */
export function usePermissions(): UsePermissionsReturn {
  const { loading, error, data } = useGetPermissionsQuery();

  if (loading) {
    return {
      loading: true,
      error: null,
      staff: null,
      auth: null,
    };
  }

  if (error || !data) {
    return {
      loading: false,
      error: error ?? new Error('User data not returned from permissions query.'),
      staff: null,
      auth: null,
    };
  }

  const availablePermissions = data.currentMembership?.permissions ?? [];
  const authorizedPermissions: Partial<AuthedPermissions> = {};
  for (const untypedPermKey in permissionsMap) {
    const permKey = untypedPermKey as PermissionKey;
    // always grant permissions if staff flag is true on current user
    if (data.currentUser?.staff || data.currentMembership?.owner) {
      authorizedPermissions[permKey] = true;
      continue;
    }

    // otherwise check if requested permission is included in current membership permissions
    authorizedPermissions[permKey] = availablePermissions.includes('headshots:' + permissionsMap[permKey]);
  }

  return {
    loading: false,
    error: null,
    staff: !!data.currentUser?.staff,
    auth: authorizedPermissions as AuthedPermissions,
  };
}
